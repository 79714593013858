:root {
  --WIDTH_SIDEBAR: 335px;
  --WIDTH_MAP_CONTROL: 40px;
  --WIDTH_MAP_POPUP: 180px;

  --HEIGHT_SIDEBAR_BIG: 85vh;
  --HEIGHT_SIDEBAR_SMALL: 30vh;

  --COLOR_WHITE: #ffffff;

  --COLOR_GRAY_0: #f0f0f0;
  --COLOR_GRAY_1: #999999;
  --COLOR_GRAY_2: #666666;

  --COLOR_BLUE: #1890ff;

  --COLOR_BLACK: #000000;

  --FONT_SIZE_LITTLE: 10px;
  --FONT_SIZE_SMALL: 13px;
  --FONT_SIZE_NORMAl: 14px;
  --FONT_SIZE_BIG: 16px;
  --FONT_SIZE_TITLE: 20px;

  --FONT_WEIGHT_BOLD: 600;

  --SPACING_NORMAL: 10px;
  --SPACING_MEDIUM: 14px;
  --SPACING_EXTRA_LARGE: 25px;

  --TRANSITION_MAP_CONTROL: 0.5s;
  --TRANSITION_SIDEBAR: 0.25s;

  --RADIUS_MAP_CONTROL: 2px;
  --RADIUS_MAP_POPUP: 6px;

  --SHADOW: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}
