@keyframes SidebarInDesktop {
  from {
    width: 0;
  }
  to {
    width: var(--WIDTH_SIDEBAR);
  }
}

@keyframes SidebarInMobile {
  from {
    height: 0;
  }
  to {
    height: var(--HEIGHT_SIDEBAR_BIG);
  }
}

@keyframes SidebarSmallInMobile {
  from {
    height: 0;
  }
  to {
    height: var(--HEIGHT_SIDEBAR_SMALL);
  }
}

@keyframes SidebarOutDesktop {
  from {
    width: var(--WIDTH_SIDEBAR);
  }
  to {
    width: 0;
  }
}

@keyframes SidebarOutMobile {
  from {
    height: var(--HEIGHT_SIDEBAR_BIG);
  }
  to {
    height: 0;
  }
}

@keyframes SidebarSmallOutMobile {
  from {
    height: var(--HEIGHT_SIDEBAR_SMALL);
  }
  to {
    height: 0;
  }
}

.sidebar {
  align-items: flex-start;
  animation-duration: var(--TRANSITION_SIDEBAR);
  animation-fill-mode: both;
  animation-name: SidebarInMobile;
  animation-timing-function: ease-in;
  background-color: var(--COLOR_WHITE);
  bottom: 0;
  box-shadow: var(--SHADOW);
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: auto;
  width: auto;
  z-index: 1002;
}

.sidebar_small {
  animation-name: SidebarSmallInMobile;
}

.sidebar__visible {
  animation-name: none;
  height: var(--HEIGHT_SIDEBAR_BIG);
  width: 100vw;
}

.sidebar_small__visible {
  height: var(--HEIGHT_SIDEBAR_SMALL);
}

.sidebar__hide {
  animation-name: SidebarOutMobile;
}

.sidebar_small__hide {
  animation-name: SidebarSmallOutMobile;
}

.container {
  display: flex;
  flex-direction: column;
  height: var(--HEIGHT_SIDEBAR_BIG);
  overflow: auto;
  padding-bottom: var(--SPACING_EXTRA_LARGE);
  width: 100vw;
}

.container_small {
  height: var(--HEIGHT_SIDEBAR_SMALL);
}

@media only screen and (orientation: landscape) {
  .sidebar {
    animation-name: SidebarInDesktop;
    bottom: auto;
    height: auto;
    min-height: 100vh;
    height: -webkit-fill-available;
    top: 0;
    width: 0;
  }

  .sidebar__hide {
    animation-name: SidebarOutDesktop;
  }

  .sidebar__visible {
    animation-name: none;
    height: auto;
    width: var(--WIDTH_SIDEBAR);
  }

  .container {
    height: 100vh;
    height: -webkit-fill-available;
    width: var(--WIDTH_SIDEBAR);
  }
}
