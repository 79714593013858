.leaflet-bottom.leaflet-custom-controls {
  margin-bottom: var(--SPACING_MEDIUM);
}

.leaflet-bar.leaflet-control,
.leaflet-control-layers.leaflet-control {
  border-radius: var(--RADIUS_MAP_CONTROL);
  border: none;
  box-shadow: var(--SHADOW);
  user-select: none;
}

.leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
  padding: 0;
}

.leaflet-control-layers.leaflet-control .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers.leaflet-control .leaflet-control-layers-base {
  display: flex;
  flex-direction: row;
}

.leaflet-control-layers.leaflet-control label {
  padding: var(--SPACING_NORMAL) var(--SPACING_MEDIUM);
  cursor: pointer;
  border-radius: var(--RADIUS_MAP_CONTROL);
}

.leaflet-control-layers.leaflet-control input {
  display: none;
}

.leaflet-control-layers.leaflet-control input:checked ~ span {
  font-weight: var(--FONT_WEIGHT_BOLD);
}

.leaflet-control-layers.leaflet-control span {
  font-size: var(--FONT_SIZE_BIG);
}

.leaflet-bar.leaflet-control .leaflet-control-zoom-in,
.leaflet-bar.leaflet-control .leaflet-control-zoom-out {
  line-height: calc(var(--WIDTH_MAP_CONTROL) - 4px);
  width: var(--WIDTH_MAP_CONTROL);
  height: var(--WIDTH_MAP_CONTROL);
}

.leaflet-bar.leaflet-control button {
  cursor: pointer;
  border-radius: var(--RADIUS_MAP_CONTROL);
}

.leaflet-bar.leaflet-control a,
.leaflet-bar.leaflet-control button,
.leaflet-control-layers.leaflet-control label {
  background-color: var(--COLOR_WHITE);
  transition: background-color var(--TRANSITION_MAP_CONTROL);
}

.leaflet-bar.leaflet-control a:hover,
.leaflet-bar.leaflet-control button:hover,
.leaflet-control-layers.leaflet-control label:hover {
  background-color: var(--COLOR_GRAY_0);
}

.leaflet-bar.leaflet-control a:first-child {
  border-bottom: 1px solid var(--COLOR_GRAY_0);
}

.leaflet-control-layers.leaflet-control label:first-child {
  border-right: 1px solid var(--COLOR_GRAY_0);
}

.leaflet-popup-content-wrapper {
  border-radius: var(--RADIUS_MAP_POPUP);
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: var(--SPACING_NORMAL);
  width: var(--WIDTH_MAP_POPUP);
}

.leaflet-popup-content-wrapper .leaflet-popup-content h3 {
  margin: 0;
  font-size: var(--FONT_SIZE_NORMAl);
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-description {
  margin: 0;
  font-size: var(--FONT_SIZE_LITTLE);
  color: var(--COLOR_GRAY_1);
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-link {
  margin: 0;
  margin-top: var(--SPACING_NORMAL);
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-link a {
  font-size: var(--FONT_SIZE_SMALL);
  color: var(--COLOR_BLUE);
}

.leaflet-popup-content-wrapper .leaflet-popup-content .popup-link a:hover {
  text-decoration: underline;
}

.editor-mode .leaflet-container {
  cursor: pointer !important;
}
