@keyframes DimmerShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes DimmerHide {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

.dimmer {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: 0;
  position: fixed;
  background-color: var(--COLOR_BLACK);
  cursor: pointer;
  animation-fill-mode: both;
  animation-duration: var(--TRANSITION_SIDEBAR);
  animation-timing-function: ease-in;
  animation-name: DimmerShow;
}

.dimmer__hide {
  animation-name: DimmerHide;
}

.dimmer__disabled {
  cursor: default;
}
